a{
    width: fit-content;
}
.card__container{
    border: 2px solid rgb(164, 164, 164);
    border-radius: 20px;
    max-width: 400px;
    /* margin: 2vh; */
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.card__container:hover
{
    transform:scale(1.09) ;
    transition: opacity 300ms, transform 300ms ;
    border-color: rgb(120, 226, 255);
}

.card__site-img{
    width: 60vw;
    border: 2px solid rgb(164, 164, 164);
    border-radius: 10px;
    margin: 5px;
}

.card__site-img:hover{
    transform: scale(1.11)  ;
    transition: opacity 300ms, transform 300ms ;
    border-color: rgb(120, 226, 255);
}

.card__desc{
    color: rgb(208, 208, 208);
    margin-bottom: 4px;
}
.card__btn{
    color: white;
    background-color: black;
    border-radius: 30px;
    border: 2px solid white;
    padding: 5px 10px;
    margin: 10px 0;
}

.card__btn:hover{
    background-color: white;
    color: black;
    font-weight: 500;
}
@media screen and (min-width: 600px) {
    .card__site-img{
        width: 300px;
    }
}